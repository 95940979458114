<template>
    <section class="cross-media-promotion-wrapper">
        <a target="_blank" href="https://hollywoodlife.com/2019/12/18/sena-kana-gold-record-up-wiz-khalifa-sheppard-interview/" class="cel-image">
            <parallaxContainer className="para-colaboration"> 
                <parallaxItem :factor="-0.3">
                    <img src="images/sena-kana/2-hollywood life.png" alt="Cross-Media Promotion">
                </parallaxItem>
            </parallaxContainer>
        </a>
        <div class="content">
            <h3>{{ $t("senaKana.mediaPromotion.title") }}</h3>
            <p>{{ $t("senaKana.mediaPromotion.text") }}</p>
        </div>
    </section>
</template>
<script>
import parallaxContainer from './../../components/parallax-container'
import parallaxItem from './../../components/parallax-item';

export default {
    components: {
        parallaxContainer, parallaxItem
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.cross-media-promotion-wrapper {
    padding: 15vw 10vw 0 10vw;
    background-color: #fff;
    display: flex;
}

.cel-image {
    position: relative;
    width:60%;

    img {
        position: relative;
        width: 100%;
    }
}

.content {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top:10vh;
    padding-left: 5vw; 

    h3 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 400;
        color:#1D1F1F;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

@media (max-width: 992px) {
    .cross-media-promotion-wrapper {
        flex-direction: column-reverse;
    }

    .cel-image, .content {
        width: 100%;
    }

    .content {
        padding-top: 0;
        padding-left: 0;

        h3 {
            @include font-size(23px);
            font-size: calc(23px + 4 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            color: #313333;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }
    }

    .cel-image {
        padding-top: 15vw; 
        margin-left: 0;
    }
}

@media (max-width: 576px) { 
    .cross-media-promotion-wrapper {
        padding: 10vw 5vw 0 5vw;
        flex-direction: column-reverse;

        .content {
            padding-left: 0;
        }
    }
}

</style>