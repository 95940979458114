<template>
    <section class="chart-promotion-wrapper">
        <div class="cel-image">
            <parallaxContainer className="para-colaboration"> 
                <parallaxItem :factor="-0.3">
                    <img src="images/sena-kana/2-apple music.png" alt="Chart promotion">
                </parallaxItem>
            </parallaxContainer>
        </div>
        <div class="cel">
            <h2>{{ $t("senaKana.promotion.title") }}</h2>
            <p>{{ $t("senaKana.promotion.text") }}</p>
        </div>
    </section>
</template>
<script>
import parallaxContainer from './../../components/parallax-container'
import parallaxItem from './../../components/parallax-item';

export default {
    components: {
        parallaxContainer, parallaxItem
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.chart-promotion-wrapper {
    padding: 10vw 10vw 5vw;
    background-color: #fff;
    display: flex;
}

.cel-image {
    width:60%;

    img {
        width: 90%;
        margin: auto;
    }
}

.cel {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5vh; 

    h2 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 400;
        color:#1D1F1F;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

@media (max-width: 992px) {

    .chart-promotion-wrapper {
        flex-direction: column-reverse;
    }

    .cel-image {
        width: 100%;
        padding-top: 15vw; 

        img {
            width: 100%;
        }
    }

    .cel {
        width: 100%;
        padding: 0;

        h2 {
            margin-bottom: 0.8em;
            @include font-size(21px);
            font-size: calc(21px + 3 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }
    }
}

@media (max-width: 576px) { 
    .chart-promotion-wrapper {
        padding: 10vw 5vw 5vw 5vw;
    }
}
</style>