<template>
    <section class="sk-featuring-wrapper">
        <div class="content">
            <h3>{{ $t("senaKana.featuring.title") }}</h3>
            <p>{{ $t("senaKana.featuring.text") }}</p>
            <a class="action" target="_blank" href="//www.youtube.com/watch?v=_qPUHJ75COo">
                <span>{{ $t("senaKana.featuring.linkText") }}</span>
                <svg width="44" height="25" viewBox="0 0 44 25" xmlns="http://www.w3.org/2000/svg"><path d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z" fill="#313333" fill-rule="nonzero"></path></svg>
            </a>
        </div>
        <div class="image">
            <imageFrame :shadowTop="8" :shadowLeft="7" class="pic-frame" url="images/sena-kana/58586642_591081241402852_2272767311448375296_n.jpg"/>
        </div>
    </section>
</template>
<script>
import imageFrame from './../../components/shadowed-pic'
export default {
    components: {
        imageFrame
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.sk-featuring-wrapper {
    padding: 5vw 10vw 0 10vw;
    background-color: #fff;
    display: flex;
}

.content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        width:100%;
        font-family: $family-bold;
        margin-bottom: 0.8em;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        line-height: 1em;
        font-weight: 400;
        color:#1D1F1F;
    }

    p {
        font-family: $family-light;
        color: #313333;
        font-size: 1.4vw;
        line-height: 1.56em;
    }
}

.image {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
    right: -5%;

    img {
        width: 100%;
    }
}

.action {
    margin-top: 5vh;
    position: relative;
    color: #313333;
    font-size: 1.8vw;
    font-family: $family-light;
    cursor: pointer;
    z-index: 0;
    padding: 10px 20px 10px;

    &::after {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      background-color: #4923D8;
      @include opacity(0);
      @include transition(all 500ms);
    }

    &:hover {
      color: #DCE6E6;
      &:after {
        @include opacity(1);
      }

      svg {
        margin-left: 32px;
        path {
            fill: #DCE6E6;
        }
      }
    }

    span {
        position: relative;
        top:-2px;
        display: inline-block;
        z-index: 2;
        font-size: 1.2vw;
        line-height: 1em;
        font-family: $family-light;
    }

    svg {
      position: relative;
      width: 25px;
      margin-left: 12px;
      display: inline-block;
      z-index: 2;
      @include transition(all 500ms);
    }
}

@media (max-width: 992px) {
    .sk-featuring-wrapper {
        padding: 5vw 10vw 10vw;
        flex-direction: column;
    }

    .content {
        width: 100%;

        padding-bottom: 5vw;

        h3 {
            @include font-size(23px);
            font-size: calc(23px + 4 * ((100vw - 375px) / 649));
        }

        p {
            font-family: $family-light;
            color: #313333;
            font-size: 1.4vw;
            line-height: 1.56em;
            will-change: transform;
            font-size: calc(16px + 2 * ((100vw - 375px) / 649));
        }

        svg {
            position: relative;
            width: 22px;
            top: -2px;
            margin-left: 12px;
            display: inline-block;
            z-index: 2;
            @include transition(all 500ms);
        }
    }

    .action {
        position: relative;
        color: #313333;
        margin-top: 0;
        cursor: pointer;
        z-index: 0;
        padding: 10px 20px 10px;

        span {
            @include font-size(18px);
            font-family: $family-light;
        }
    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        right: 0;

        img {
            width: 90%;
        }  
    }
}

@media (max-width: 576px) {
    .sk-featuring-wrapper {
        padding: 10vw 5vw 10vw 5vw;
    }
   .content {
        svg {
            position: relative;
            width: 20px;
            top: -6px;
            margin-left: 12px;
            display: inline-block;
            z-index: 2;
            @include transition(all 500ms);
        }

        a {
            padding: 5vw 0 5vw;
            margin-top: 3vw;

            span{
                @include font-size(15px);
                font-size: 4.8vw;
            }
            
        }
    }
}

</style>